import React from 'react'

function AndroidButton() {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M45.2812 25.8125C44.6406 25.8125 44.125 26.3281 44.125 26.9688C44.125 27.6094 44.6406 28.125 45.2812 28.125C45.9219 28.125 46.4375 27.6094 46.4375 26.9688C46.4531 26.3281 45.9375 25.8125 45.2812 25.8125Z"
                fill="#27D4A9" />
            <path
                d="M36 25.8125C35.3594 25.8125 34.8438 26.3281 34.8438 26.9688C34.8438 27.6094 35.3594 28.125 36 28.125C36.6406 28.125 37.1563 27.6094 37.1563 26.9688C37.1563 26.3281 36.6406 25.8125 36 25.8125Z"
                fill="#27D4A9" />
            <path
                d="M73.75 0H6.25C2.79688 0 0 2.79688 0 6.25V73.75C0 77.2031 2.79688 80 6.25 80H73.75C77.2031 80 80 77.2031 80 73.75V6.25C80 2.79688 77.2031 0 73.75 0ZM28.25 45.0312C28.25 46.3125 27.2031 47.3594 25.9219 47.3594C24.6406 47.3594 23.5938 46.3125 23.5938 45.0312V35.0938C23.5938 33.8125 24.6406 32.7656 25.9219 32.7656C27.2031 32.7656 28.25 33.8125 28.25 35.0938V45.0312ZM51.4844 34.7031V36.2812V49.8125C51.4844 51.1719 50.3906 52.2656 49.0312 52.2656H47.2656C47.3281 52.4688 47.3594 52.6875 47.3594 52.9062V53.0312V53.8125V57.8125C47.3594 59.0938 46.3125 60.1406 45.0312 60.1406C43.75 60.1406 42.7031 59.0938 42.7031 57.8125V53.8125V53.0312V52.9062C42.7031 52.6875 42.7344 52.4688 42.7969 52.2656H38.4844C38.5469 52.4688 38.5781 52.6875 38.5781 52.9062V53.0312V53.8125V57.8125C38.5781 59.0938 37.5312 60.1406 36.25 60.1406C34.9688 60.1406 33.9219 59.0938 33.9219 57.8125V53.8125V53.0312V52.9062C33.9219 52.6875 33.9531 52.4688 34.0156 52.2656H32.25C30.8906 52.2656 29.7969 51.1719 29.7969 49.8125V36.2812V34.7031V32.7656H29.9688H51.3125H51.4844V34.7031ZM29.7969 31.2344C29.7969 27.9219 32.0156 25.0156 35.3125 23.4844L34.875 22.8281L34.4375 22.1875L33.4688 20.7344C33.3594 20.5625 33.4062 20.3125 33.5781 20.2031C33.75 20.0781 34 20.1406 34.1094 20.3125L35.1406 21.8594L35.5781 22.5156L36.0156 23.1719C37.4219 22.625 38.9688 22.3281 40.625 22.3281C42.2656 22.3281 43.8281 22.625 45.2344 23.1719L45.6719 22.5156L46.1094 21.8594L47.1406 20.3125C47.25 20.1406 47.5 20.0781 47.6719 20.2031C47.8438 20.3281 47.9062 20.5625 47.7812 20.7344L46.8125 22.1875L46.4062 22.8281L45.9688 23.4844C49.25 25.0156 51.4688 27.9062 51.4688 31.2344H29.7969ZM57.6719 45.0312C57.6719 46.3125 56.625 47.3594 55.3438 47.3594C54.0625 47.3594 53.0156 46.3125 53.0156 45.0312V35.0938C53.0156 33.8125 54.0625 32.7656 55.3438 32.7656C56.625 32.7656 57.6719 33.8125 57.6719 35.0938V45.0312Z"
                fill="#27D4A9" />
        </svg>
    )
}

export default AndroidButton
