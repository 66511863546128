import React from 'react'
import './PopUp.css'

function PopUp(props) {
    if (props.isView) {
        return (
            <div className='popup'>
                <h1>Prochainement</h1>
                <h3>Cette fonctionnalité arrivera très prochainement !<br /> Nous somme en pleine avancement dans le developpement de l'application !</h3>
                <button onClick={() => props.setIsView(false)}>Fermer</button>
            </div>
        )
    } else {
        return <></>
    }
}

export default PopUp