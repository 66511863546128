import React from 'react'
import './Prix.css'
import Check from '../../components/ListeIcon/Check'
import NoCheck from '../../components/ListeIcon/NoCheck'

function Prix() {
    return (
        <div className='prix'>
            <h1>Choisissez votre tarifaction idéal</h1>
            <div className='card_container'>
                <div className='card'>
                    <h1>Free</h1>
                    <p>La solution gratuite pour tout débutant sur l'application !</p>
                    <h2><strong>0,00</strong>€/par mois</h2>
                    <div className='button_container'><button>Selectionner</button></div>
                    <hr />
                    <ul>
                        <li><Check />1 seul groupe</li>
                        <li><Check />Jusqu'à 3 stockage</li>
                        <li><NoCheck />Pas d'ami dans les groupes</li>
                        <li><NoCheck />Quelques publicités</li>
                    </ul>
                </div>

                <div className='card choix'>
                    <h1>Starter</h1>    
                    <p>La solution parfaite pour une utilisation régulière !</p>
                    <h2><strong>2,99</strong>€/par mois</h2>
                    <div className='button_container'><button>Selectionner</button></div>
                    <hr />
                    <ul>
                        <li><Check />Jusqu'à 3 groupes</li>
                        <li><Check />Jusqu'à 3 amis par groupe</li>
                        <li><Check />Aucune de limite de stockage</li>
                        <li><Check />Pas de publicité</li>
                    </ul>

                    <div className='choix_container'>
                        Choix de l'equipe
                    </div>
                </div>

                <div className='card'>
                    <h1>Premimum</h1>
                    <p>La solution pour une utilisation complète sans restriction !</p>
                    <h2><strong>4,99</strong>€/par mois</h2>
                    <div className='button_container'><button>Selectionner</button></div>
                    <hr />
                    <ul>
                        <li><Check />Aucune limite de groupe</li>
                        <li><Check />Autant d'ami que souhaitez par groupe</li>
                        <li><Check />Aucune de limite de stockage</li>
                        <li><Check />Accès au proposition de recette personnalisé</li>
                        <li><Check />Pas de publicité</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Prix