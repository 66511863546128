import React from 'react'
import './Slider.css'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import Prix from '../Prix/Prix'

function Slider() {
    const navigate = useNavigate();
    const [x, setX] = useState(0);

    // let x = 0;

    const slider = () => {
        switch (x) {
            case 0:
                return <img className='slide' src='./Image/groupe.png' alt='slide 0' />
            case 1:
                return <img className='slide' src='./Image/stockage.png' alt='slide 1' />
            case 2:
                return <img className='slide' src='./Image/produit.png' alt='slide 2' />
            case 3:
                return <img className='slide' src='./Image/detail.png' alt='slide 3' />
            case 4:
                return <img className='slide' src='./Image/Scan/1.png' alt='slide 4' />
            case 5:
                return <Prix />
            default:
                return <></>
        }
    }

    return (
        <div className='slider'>
            <button className='prev' onClick={() => x > 0 ? setX(x - 1) : setX(5)}>
                <svg width="18" height="33" viewBox="0 0 18 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.2168 0.732244C16.2405 -0.244081 14.6578 -0.244081 13.6813 0.732244L1.46273 12.9628C-0.48825 14.9158 -0.487475 18.0803 1.46445 20.032L13.6903 32.258C14.6668 33.2343 16.2495 33.2343 17.226 32.258C18.2023 31.2818 18.2023 29.6988 17.226 28.7225L6.76178 18.2583C5.78547 17.282 5.78547 15.6993 6.76178 14.7228L17.2168 4.26777C18.1933 3.29147 18.1933 1.70854 17.2168 0.732244Z" fill="#505050" />
                </svg>
            </button>

            <button className='next' onClick={() => x < 5 ? setX(x + 1) : setX(0)}>
                <svg width="18" height="33" viewBox="0 0 18 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.741268 32.2581C1.71754 33.2344 3.30054 33.2344 4.27679 32.2581L16.4953 20.0274C18.4463 18.0746 18.4455 14.9101 16.4938 12.9581L4.26779 0.732225C3.29154 -0.244075 1.70854 -0.244075 0.732244 0.732225C-0.244081 1.70855 -0.244081 3.29145 0.732244 4.26777L11.1963 14.7319C12.1728 15.7081 12.1728 17.2911 11.1963 18.2674L0.741268 28.7226C-0.235057 29.6989 -0.235057 31.2816 0.741268 32.2581Z" fill="#505050" />
                </svg>
            </button>

            <div className='container'>
                {slider()}
            </div>

            <button className='closed' onClick={() => navigate("/")}>
                <svg width="22" height="22" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.95376 9.73094C1.95376 9.73094 7.21974 4.2851 9.60025 1.82328M1.75104 2.02846L9.6587 9.67496" stroke="black" stroke-width="3" stroke-linecap="round" />
                </svg>
            </button>
        </div>
    )
}

export default Slider