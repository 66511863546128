import React, {useState} from 'react'
import './Accueil.css'
import { Link} from 'react-router-dom';

import Android from '../../components/Button/Android'
import Iphone from '../../components/Button/Iphone'
import PopUp from '../../components/PopUp/PopUp';

function Accueil() {
    const [isView, setIsView] = useState(false)

    return (
        <div className="accueil">
            <img className="logo" src="./Image/logo.png" alt="SyncFood logo" />

            <h1>TELECHARGEMENT</h1>

            <div className='button_container'>
                <a href="https://play.google.com/store/apps?hl=fr" rel="noreferrer" target="_blank">
                    <Android />
                </a>

                {/* <a href="https://www.apple.com/fr/app-store/" rel="noreferrer" target="_blank">
                    <Iphone />
                </a> */}
                <a onClick={() => setIsView(true)} rel="noreferrer">
                    <Iphone />
                </a>

                <PopUp isView={isView} setIsView={setIsView}/>
            </div>

            <button><Link to="/slider">Découvrir</Link></button>

            <Link className="confidential_link" to="/confidentialite">Politique de confidentialité</Link>
        </div>
    )
}

export default Accueil