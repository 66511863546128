import React from 'react'
import './Confidentialite.css'
import { useNavigate } from 'react-router-dom';

function Confidentialite() {
  const navigate = useNavigate();

  return (
    <div className='confidentialite'>
      <button className='closed' onClick={() => navigate("/")}>
        <svg width="22" height="22" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.95376 9.73094C1.95376 9.73094 7.21974 4.2851 9.60025 1.82328M1.75104 2.02846L9.6587 9.67496" stroke="black" stroke-width="3" stroke-linecap="round" />
        </svg>
      </button>
      
      <h1>Politique de confidentialité</h1>
      <section>
        <h2>1. Collecte des informations</h2>
        <p>Nous collectons uniquement les informations essentielles suivantes :</p>
        <ul>
          <li>
            <strong>Informations utilisateur :</strong>
            <ul>
              <li>Nom ou pseudonyme</li>
              <li>Adresse e-mail (nécessaire pour la création d'un compte)</li>
              <li>Stock alimentaire</li>
              <li>Paramètres de l'application</li>
            </ul>
          </li>
          <li>
            <strong>Utilisation de l'appareil photo :</strong>
            <p>L'application utilise l'appareil photo de votre appareil pour scanner et enregistrer les articles alimentaires dans votre stock. Les images capturées ne sont pas stockées ni sur votre appareil, ni transmises à nos serveurs.</p>
          </li>
        </ul>
      </section>
      <section>
        <h2>2. Utilisation des informations</h2>
        <p>Les informations collectées sont utilisées exclusivement pour les finalités suivantes :</p>
        <ul>
          <li><strong>Gestion du stock alimentaire :</strong> Enregistrer, suivre et organiser vos articles alimentaires, gérer les dates de péremption et les quantités disponibles</li>
          <li><strong>Amélioration de l'expérience utilisateur :</strong> Personnaliser les fonctionnalités de l'application en fonction de vos préférences.</li>
        </ul>
      </section>
      <section>
        <h2>3. Partage des informations</h2>
      </section>
      <p>Nous ne partageons aucune de vos informations personnelles avec des tiers.
        Vos données restent strictement confidentielles et utilisées uniquement dans
        le cadre de l'application.</p>
      <section>
        <h2>4. Sécurité des données</h2>
      </section>
      <p>
        Nous mettons en place des mesures de sécurité appropriées pour protéger vos données contre toute perte, utilisation abusive, accès non autorisé, divulgation, altération ou destruction.
      </p>
      <strong>Ces mesures incluent :</strong>
      <ul>
        <li><strong>Chiffrement des données : </strong>Les informations stockées sur nos serveurs sont chiffrées pour garantir leur confidentialité. </li>
        <li><strong>Accès restreint : </strong>L'accès aux informations est limité aux employés et aux sous-traitants qui en ont besoin pour les fins décrites dans cette politique. </li>
        <li><strong>Mises à jour de sécurité : </strong>Nous maintenons l'application à jour avec les dernières pratiques de sécurité pour assurer la protection continue de vos données.</li>
      </ul>
      <section>
        <h2>5. Conservation des données </h2>
      </section>
      <p>Nous conservons vos informations personnelles aussi longtemps que nécessaire pour
        fournir les services de l'application et remplir les objectifs décrits dans
        cette politique. Vous pouvez demander la suppression de vos informations
        personnelles à tout moment en nous contactant à l'adresse si dessous. </p>
      <section>
        <h2>6. Vos droits</h2>
      </section>
      <p>En vertu de la législation applicable, vous disposez des droits suivants concernant vos informations personnelles : </p>
      <ul>
        <li><strong>Accès et rectification : </strong>Vous avez le droit d'accéder à vos informations personnelles et de demander leur rectification si elles sont incorrectes ou incomplètes.</li>
        <li><strong>Suppression : </strong>Vous pouvez demander la suppression de vos informations personnelles, sous réserve des obligations légales de conservation.</li>
        <li><strong>Opposition : </strong>Vous avez le droit de vous opposer au traitement de vos informations personnelles pour des motifs légitimes.</li>
      </ul>
      <section>
        <h2>7. Modifications de la politique</h2>
      </section>
      <p>Nous nous réservons le droit de modifier cette politique à tout moment.
        Toute modification sera publiée sur cette page et prendra effet immédiatement.
        Nous vous encourageons à consulter régulièrement cette page pour prendre connaissance des éventuelles mises à jour.
      </p>
      <section>
        <h2>8. Contact</h2>
      </section>
      <p>Si vous avez des questions ou des préoccupations concernant cette politique ou vos informations personnelles, veuillez nous contacter à l'adresse suivante : <strong>syncfood.contact@gmail.com</strong>.</p>
      <section>
        <h3>Date de dernière mise à jour : 10/07/2024 </h3>
        <p>Merci d'utiliser notre application. Nous nous engageons à protéger au mieux votre vie
          privée et à garantir la sécurité de vos données.</p>
      </section>
    </div>
  )
}

export default Confidentialite
