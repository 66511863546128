import React from 'react'

function IphoneButton() {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M73.75 0H6.25C2.79688 0 0 2.79688 0 6.25V73.75C0 77.2031 2.79688 80 6.25 80H73.75C77.2031 80 80 77.2031 80 73.75V6.25C80 2.79688 77.2031 0 73.75 0ZM41.4219 22.2031C42.8906 20.4219 45.3906 19.0625 47.5312 18.9062C47.8906 21.3438 46.8906 23.7188 45.5781 25.4062C44.1719 27.2188 41.75 28.6094 39.4062 28.5312C39 26.2031 40.0938 23.8125 41.4219 22.2031ZM54.5938 53.1094C52.9062 55.6719 50.5312 58.875 47.5781 58.9062C44.9531 58.9375 44.2813 57.2031 40.7344 57.2188C37.1875 57.2344 36.4375 58.9375 33.8125 58.9062C30.8594 58.875 28.6094 55.9844 26.9219 53.4219C22.2031 46.2344 21.7031 37.8125 24.625 33.3281C26.6875 30.1406 29.9531 28.2812 33.0312 28.2812C36.1562 28.2812 38.1094 30 40.7031 30C43.2031 30 44.7344 28.2812 48.3438 28.2812C51.0781 28.2812 53.9688 29.7656 56.0312 32.3438C49.2812 36.0469 50.375 45.6875 57.2031 48.2656C56.25 50.3281 55.7969 51.2656 54.5938 53.1094Z"
                fill="#27D4A9" />
        </svg>
    )
}

export default IphoneButton