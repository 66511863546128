import './App.css';
import { Routes, Route } from 'react-router-dom';
import Accueil from './page/Accueil/Accueil';
import Slider from './page/Slider/Slider';
import Confidentialite from './page/Confidentialite/Confidentialite';
import Error from './page/Error/Error';
import Prix from './page/Prix/Prix'

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Accueil />} />
        <Route path="/slider" element={<Slider />} />
        <Route path="/confidentialite" element={<Confidentialite />} />
        <Route path="/prix" element={<Prix />} />
        <Route path="*" element={<Error />} />
      </Routes>

      <img className="top" src="./SVG/top.svg" alt='' />
      <img className="bottom" src="./SVG/bottom.svg" alt='' />
    </>
  );
}

export default App;
